.minimal-scrollbar::-webkit-scrollbar {
    width: 4px;
}

.minimal-scrollbar::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 4px;
}


.minimal-scrollbar::-webkit-scrollbar-thumb:hover {
    background: #555;
}